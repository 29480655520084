<template>
    <div class="cooperation-section" id="cooperation">
        <div class="container">
            <div class="page-title" :class="{'active': copTitle}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'cop-title'),
                    intersection: {
                        threshold: .7
                    },
                }"
            >
                <h2><span>{{mainLang.cooperationSection.pageTitle.black}}</span>{{mainLang.cooperationSection.pageTitle.color}}</h2>
            </div>
        </div>
        <copPromo></copPromo>
        <copInfo></copInfo>
        <copList></copList>
        <copOrange></copOrange>
        <copCollapse></copCollapse>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import copPromo from '@/components/cooperation/cooperation-promo.vue'
    import copInfo from '@/components/cooperation/cooperation-info.vue'
    import copList from '@/components/cooperation/cooperation-list.vue'
    import copOrange from '@/components/cooperation/cooperation-orange.vue'
    import copCollapse from '@/components/cooperation/cooperation-collapse.vue'

    export default {
        name: 'cooperation-section',
        components: {
            copPromo,
            copInfo,
            copList,
            copOrange,
            copCollapse
        },
        data () {
            return {
                copTitle: false,
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            visibilityChanged (isVisible, entry, section) {
                if (isVisible == true) {
                    if (section == 'cop-title') {
                        this.copTitle = true;
                    } 
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/cooperation/cooperation.scss';
    @import '@/styles/components/cooperation/respond/cooperation-respond.scss';
</style>
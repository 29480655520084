<template>
    <div class="cooperation-orange" :class="{'active' : showOrange}"
        v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'cop-orange'),
            intersection: {
                threshold: .6
            },
        }"
    >
        <div class="container">

            <div class="orange-items">
                <div class="orange-logo">
                    <img :src="arrows" alt="" class="arrows">
                    <div class="logo">
                        <img :src="logo" alt="">
                    </div>
                </div>
                <div class="orange-items-in">
                    <div class="item" v-for="(item, index) in mainLang.cooperationSection.cooperation_orange" :key="index">
                        <h3>{{item.title}}</h3>
                        <img :src="item.img" alt="">
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'cooperation-orange',
        components: {
        },
        components: {
        },
        data () {
            return {
                showOrange: false,
                logo: require('@/assets/images/logo.svg'),
                arrows: require('@/assets/images/samples/cooperation/orange_arrows.png'),
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            visibilityChanged (isVisible, entry, section) {
                if (isVisible == true) {
                    if (section == 'cop-orange') {
                        this.showOrange = true;
                    } 
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/cooperation/cooperation-orange.scss';
    @import '@/styles/components/cooperation/respond/cooperation-orange-respond.scss';
</style>
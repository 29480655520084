<template>
    <div class="cooperation-promo">
        <div class="container">

            <div class="cooperation-promo-container" :class="{'active' : showPromo}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'cop-promo'),
                    intersection: {
                        threshold: .7
                    },
                }"
            >
                <div class="dots-promo" :style="'background-image:url(' + dots_promo + ')'"></div>
                <div class="cooperation-promo">
                    <img :src="img_promo" alt="">
                </div>
            </div>

            <div class="cooperation-promo-desc" :class="{'active' : showPromoDesc}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'cop-promo-desc'),
                    intersection: {
                        threshold: .7
                    },
                }"
            >
                <div class="column-container">
                    <div class="col-right">
                        <div class="desc-images">
                            <img :src="arrows_cop" alt="" class="arrows">
                            <img :src="cop_time" alt="" class="cubic">
                        </div>
                        <div class="numbers-container">
                            <div class="number">
                                <number
                                    class="bold transition"
                                    ref="numberCooperation"
                                    :from="numberFrom"
                                    :format="theFormat"
                                    :to="number"
                                    :duration="duration"
                                    animationPaused
                                    easing="Power1.easeIn"
                                    @complete="completed"/>
                                <span class="year">{{mainLang.cooperationSection.year}}</span>
                            </div>
                            <div class="number-desc">
                                <p>{{mainLang.cooperationSection.desc_number}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-left">
                        <div class="promo-full-desc">
                            <p>{{mainLang.cooperationSection.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'cooperation-promo',
        components: {
        },
        components: {
        },
        data () {
            return {
                // COMPONENT VARS
                numberFrom: 0,
                number: 13,
                duration: 2,
                scaleClass: false,
                showPromo: false,
                showPromoDesc: false,
                /// CONTENT
                img_promo: require('@/assets/images/samples/cooperation/cooperation_pic_01.png'),
                arrows_cop: require('@/assets/images/samples/cooperation/arrows-cop.svg'),
                cop_time: require('@/assets/images/samples/cooperation/time.svg'),
                dots_promo: require('@/assets/images/dots-promo.svg'),
                
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            theFormat(number) {
                return number.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            },
            completed() {
            },
            playAnimation() {
                this.$refs.numberCooperation.play();
            },
            visibilityChanged (isVisible, entry, section) {
                if (isVisible == true) {
                    if (section == 'cop-promo') {
                        this.showPromo = true;
                    } else if (section == 'cop-promo-desc') {
                        this.showPromoDesc = true;
                        this.playAnimation();
                    } 
                }
            }
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/cooperation/cooperation-promo.scss';
    @import '@/styles/components/cooperation/respond/cooperation-promo-respond.scss';
</style>
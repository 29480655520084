<template>
    <div class="cooperation-list" :class="{'active': showList}"
        v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'cop-list'),
            intersection: {
                threshold: .7
            },
        }"
    >
        <div class="container">

            <div class="cooperation-list-container">
                <div class="column-list col-img">
                    <img :src="cop_pic" alt="">
                </div>
                <div class="column-list col-orange">
                    <h2>{{mainLang.cooperationSection.cooperation_title_left}}</h2>
                    <div class="cop-orange-list">
                        <div class="item" v-for="(item, index) in mainLang.cooperationSection.icons_list" :key="index">
                            <div class="item-img">
                                <img :src="item.icon" alt="">
                            </div>
                            <div class="item-desc">
                                <p>{{item.title}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column-list col-list">
                    <h2>{{mainLang.cooperationSection.cooperation_title_right}}</h2>
                    <div class="cop-check-list">
                        <ul>
                            <li v-for="(item, index) in mainLang.cooperationSection.cooperation_list" :key="index">
                                <img :src="check" alt="">
                                <p>{{item}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'cooperation-list',
        components: {
        },
        components: {
        },
        data () {
            return {
                showList: false,
                cop_pic: require('@/assets/images/samples/cooperation/cooperation_pic_02.png'),
                check: require('@/assets/images/samples/cooperation/check.svg'),
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            visibilityChanged (isVisible, entry, section) {
                if (isVisible == true) {
                    if (section == 'cop-list') {
                        this.showList = true;
                    } 
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/cooperation/cooperation-list.scss';
    @import '@/styles/components/cooperation/respond/cooperation-list-respond.scss';
</style>
<template>
    <div class="strategy-section" id="strategy">
        <div class="container">
            <div class="page-title" :class="{'active' : showTitle}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'strategy-title'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <h2><span>{{mainLang.strategySection.pageTitle.black}}</span>{{mainLang.strategySection.pageTitle.color}}</h2>
            </div>

            <div class="strategy-promo-container" :class="{'active' : showPromo}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'strategy-promo'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <div class="dots-promo" :style="'background-image:url(' + dots_promo + ')'"></div>
                <div class="strategy-promo">
                    <img :src="img_promo" alt="">
                </div>
            </div>

            <div class="strategy-promo-desc" :class="{'active' : showPromoDesc}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'strategy-promo-desc'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <div class="column-container">
                    <div class="col-left">
                        <div class="desc-images">
                            <img :src="arrows_promo" alt="" class="arrows">
                            <img :src="people" alt="" class="cubic">
                        </div>
                        <div class="numbers-container">
                            <div class="number">
                                <span class="fly">></span>
                                <number
                                    class="bold transition"
                                    ref="numberStrategy"
                                    :from="numberFrom"
                                    :format="theFormat"
                                    :to="number"
                                    :duration="duration"
                                    animationPaused
                                    easing="Power1.easeIn"
                                    @complete="completed"/>
                            </div>
                            <div class="number-desc">
                                <p>{{mainLang.strategySection.desc_number}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-right">
                        <div class="promo-full-desc">
                            <p>{{mainLang.strategySection.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="strategy-desc"  :class="{'active' : showDesc}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'strategy-desc'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <div class="desc desc-left">
                    <img :src="checkpoint" alt="" class="check">
                    <div class="desc-in">
                        <p v-for="(item, index) in mainLang.strategySection.desc_left" :key="index">{{item}}</p>
                    </div>  
                </div>
                <div class="desc desc-right">
                    <img :src="checkpoint" alt="" class="check">
                    <div class="desc-in">
                        <p v-for="(item, index) in mainLang.strategySection.desc_right" :key="index">{{item}}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'strategy-section',
        components: {
        },
        data () {
            return {
                // COMPONENT VARS
                numberFrom: 0,
                number: 400,
                duration: 5,
                scaleClass: false,

                showTitle: false,
                showPromo: false,
                showPromoDesc: false,
                showDesc: false,

                /// CONTENT
                img_promo: require('@/assets/images/samples/slide-pic-04.png'),
                dots_promo: require('@/assets/images/dots-promo.svg'),
                arrows_promo: require('@/assets/images/arrows_promo.svg'),
                people: require('@/assets/images/people.svg'),
                checkpoint: require('@/assets/images/checkpoint_orange.svg'),
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            theFormat(number) {
                return number.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            },
            completed() {
                // console.log("Animation ends!");
            },
            playAnimation() {
                setTimeout(() => {
                    this.$refs.numberStrategy.play();
                }, 300);
            },
            visibilityChanged (isVisible, entry, section) {
                if (isVisible == true) {
                    if (section == 'strategy-title') {
                        this.showTitle = true;
                    } else if (section == 'strategy-promo-desc') {
                        this.showPromoDesc = true;
                        this.playAnimation();
                    } else if (section == 'strategy-promo') {
                        this.showPromo = true;
                    } else if (section == 'strategy-desc') {
                        this.showDesc = true;
                    } 
                }
            }
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/strategy/strategy.scss';
    @import '@/styles/components/strategy/strategy-respond.scss';
</style>
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import LoadScript from "vue-plugin-load-script";
import Maska from 'maska'
import VueSmoothScroll from 'vue3-smooth-scroll'
import VueNumber from 'vue-number-animation'
import VueObserveVisibility from 'vue3-observe-visibility'

window.axios = require('axios');
window.$ = window.jQuery = require('jquery');

window.GlobalLang = localStorage.getItem('languageOrangeGroupSite');

if (window.GlobalLang === null) {
    window.GlobalLang = 'ru';
    setDataLang();
} else {
    setDataLang();
} 

function setDataLang() {
    document.querySelector('body').dataset.language = window.GlobalLang;
    store.dispatch('installMainLang_Global', window.GlobalLang);
}

const app = createApp(App).use(store);
app.use(LoadScript)
app.use(Maska)
app.use(VueNumber)
app.use(VueObserveVisibility)
app.use(VueSmoothScroll, {
    updateHistory: false,
    duration: 700,
    offset: -100,
})
app.mount('#app');
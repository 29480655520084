<template>
    <div class="form-container">
        <Form class="form-contacts" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" v-if="formVisible">
            <div class="form-in">
                <TextInput
                    name="name"
                    type="text"
                    :placeholder="mainLang.formSetup.name"
                    :class="{'error' : errors.name}"
                />
                <TextInput
                    name="company"
                    type="text"
                    :placeholder="mainLang.formSetup.company"
                    :class="{'error' : errors.company}"
                />
                <TextInput
                    name="phone"
                    type="text"
                    :placeholder="mainLang.formSetup.phone"
                    v-maska="'+7 (###) ###-##-##'"
                    :class="{'error' : errors.phone}"
                />
                <TextInput
                    name="email"
                    type="text"
                    :placeholder="mainLang.formSetup.email"
                    :class="{'error' : errors.email}"
                />
                <TextInput
                    name="message"
                    type="textarea"
                    class="form-area"
                    :placeholder="mainLang.formSetup.message"
                    :class="{'error' : errors.message}"
                />
                <div class="btn-send">
                    <button class="btn btn-submit btn-success" type="submit" :disabled="errors.name || errors.phone || errors.email">{{mainLang.formSetup.btn}}</button>
                    <span class="anounce">{{mainLang.formSetup.anounce}}</span>
                </div>
            </div>
            
        </Form>
        <div class="successMessage" v-if="!formVisible">
            <h3>{{mainLang.formSetup.sucsess_msg}}</h3>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { Form } from "vee-validate";
    import * as Yup from "yup";
    import { ref } from 'vue'
    import TextInput from "@/components/forms/TextInput.vue";
    export default {
        name: 'FormFields',
        // props: ['titles'],
        data () {
            return {
                phone: '',
                name: '',
                mail: '',
                company: '',
                message: '',
                agree: false,
                errorMsg: false,
                formVisible: true,
            }
        },
        components: {
            TextInput,
            Form,
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        setup() {
            const schema = Yup.object().shape({
                name: Yup.string()
                    .required('Title is required')
                    .matches(/^([а-яА-ЯёЁa-zA-Z].*[\s\.]*){3,35}$/),
                company: Yup.string()
                    .required('Title is required')
                    .matches(/^([а-яА-ЯёЁa-zA-Z0-9_\.\+-].*[\s\.]*){2,100}$/),
                phone: Yup.string()
                    .required('First Name is required')
                    .matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10,10}$/),
                email: Yup.string()
                    .required('Last name is required')
                    .matches(/[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+/),
                message: Yup.string()
                    .required('Title is required')
                    .matches(/^([а-яА-ЯёЁa-zA-Z0-9_\.\+-].*[\s\.]*){1,500}$/),
            });
            return {
                schema,
            };
        },
        methods: {
            onSubmit(values) {
                this.formVisible = false;
                var querystring = require('querystring');
                console.log('ASDASDASDASDAS', values);
                axios.post('/formSend.php', querystring.stringify(values)).then(() => {
                    dataLayer.push({'event' : 'form_sent'})
                });
                $.ajax({
                    url: "https://docs.google.com/forms/d/e/1FAIpQLSejp3hL2L-RYjc49LX85ApMjMrmvUVtGGeTyV3su8aA9X95fg/formResponse",
                    data: {
                        // name
                        "entry.998812151": values.name,
                        // email
                        "entry.2027200337": values.company,
                        // phone
                        "entry.1996133341": values.phone,
                        // email
                        "entry.1270572673": values.email,
                        // email
                        "entry.679656044": values.message,
                    },
                    type: "POST",
                    dataType: "xml"
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/components/forms/form.scss';
    @import '@/styles/components/forms/forms-respond.scss';
</style>

<template>
    <div class="history-section" id="history" :class="{'active' : showHistorySection}">
        <div class="container"
            v-observe-visibility="{
                callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'history-section'),
                intersection: {
                    threshold: .3
                },
            }"
        >
            <div class="history-promo">
                <div class="promo-pic">
                    <img :src="img_promo" alt="">
                </div>
                <div class="section-title">
                    <h2><span>{{mainLang.historySection.pageTitle.black}}</span>{{mainLang.historySection.pageTitle.color}}</h2>
                </div>
                <div class="history-carousel">
                    <swiper 
                        :modules="modules" 
                        :navigation="swiperOptions.navigation"
                        :pagination="swiperOptions.pagination"
                        :breakpoints="swiperOptions.breakpoints"
                        effect="slide"
                        :speed="1000"
                        :slides-per-view="3"
                        :slides-per-group="3"
                        :space-between="80"
                        :observer="true"
                        :observe-parents="true"
                        :loop="false"
                        @swiper="onSwiper"
                    >
                        <swiper-slide v-for="(item, index) in mainLang.historySection.history_items" :key="index">
                            <div class="slide-content">
                                <div class="date"><span>{{item.year}}</span></div>
                                <p>{{item.title}}</p>
                            </div>
                        </swiper-slide>
                    </swiper>

                    <div class="swiper-controls history-info-controls">
                        <div id="historyPref" class="history-prev-slide slider-btn">
                            <svgIcon name="arrow-left" />
                        </div>
                        <div id="historyNext" class="history-next-slide slider-btn">
                            <svgIcon name="arrow-right" />
                        </div>
                        <div class="swiper-pagination swiper-pagintaion-history" id="historyPagination"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { EffectFade, Autoplay, Navigation, Pagination } from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
    import svgIcon from '@/components/partials/SvgIcon.vue';
	// Import Swiper styles
    import 'swiper/scss/pagination';
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';
    export default {
        name: 'history-section',
        components: {
        },
        components: {
            Swiper,
			SwiperSlide,
			svgIcon,
        },
        setup() {
            const onSwiper = (swiper) => {
                swiper.slideTo(11);
            };
			return {
				EffectFade,
				modules: [Autoplay, EffectFade, Navigation, Pagination],
                onSwiper,
			};
		},
        data () {
            return {
                // COMPONENT VARS
                showHistorySection: false,
                swiperOptions: {
                    navigation: {
                        nextEl: '#historyNext',
                        prevEl: '#historyPref'
                    },
                    pagination: {
                        el: '#historyPagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: true
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
							spaceBetween: 10
                        },
						415: {
							slidesPerView: 1,
                            slidesPerGroup: 1,
							spaceBetween: 10
						},
						812: {
							slidesPerView: 2,
                            slidesPerGroup: 2,
							spaceBetween: 20
						},
						1024: {
							slidesPerView: 3,
                            slidesPerGroup: 3,
							spaceBetween: 80
						}
					}
                },
                /// CONTENT
                img_promo: require('@/assets/images/samples/history-pic-01.png'),
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            setactiveSlide() {
                // this.swiper.activeIndex = 11;
                // console.log(this.$refs.mySwiper.swiper)
                this.$refs.mySwiper.$swiper.slideTo(10)
            },
            visibilityChanged (isVisible, entry, section) {
                if (isVisible == true) {
                    if (section == 'history-section') {
                        this.showHistorySection = true;
                    } 
                }
            }
        },
        created() {
        },
        mounted() {
            // this.setactiveSlide();
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/history/history.scss';
    @import '@/styles/components/history/history-respond.scss';
</style>
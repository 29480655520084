<template>
    <div class="nav-main">
        <ul>
            <li v-for="(item, index) in mainLang.nav" :key="index">
                <a :href="item.href" v-smooth-scroll :class="'menu-link-' + item.href" :id="'menu-link-' + item.href">{{item.name}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'navigation-main',
        components: {
        },
        data () {
            return {
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
    }
</script>

<style lang="scss">
    @import '@/styles/components/nav-main/nav-main.scss';
    @import '@/styles/components/nav-main/nav-main-respond.scss';
</style>
<template>
    <div class="home">
        <indexPromo></indexPromo>
        <about v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'about'),
            intersection: {
                threshold: .3
            },
        }"></about>
        <distribution v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'distribution'),
            intersection: {
                threshold: .3
            },
        }"></distribution>
        <strategy v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'strategy'),
            intersection: {
                threshold: .3
            },
        }"></strategy>
        <history v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'history'),
            intersection: {
                threshold: .3
            },
        }"></history>
        <cooperation v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'cooperation'),
            intersection: {
                threshold: .3
            },
        }"></cooperation>
        <contacts v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'contacts'),
            intersection: {
                threshold: .3
            },
        }"></contacts>
    </div>
</template>

<script>
import indexPromo from '@/components/index-promo/index-promo.vue'
import about from '@/components/about/about.vue'
import distribution from '@/components/distribution/distribution.vue'
import strategy from '@/components/strategy/strategy.vue'
import history from '@/components/history/history.vue'
import cooperation from '@/components/cooperation/cooperation.vue'
import contacts from '@/components/contacts/contacts.vue'

export default {
    name: 'HomeView',
    components: {
        indexPromo,
        about,
        distribution,
        strategy,
        history,
        cooperation,
        contacts,
    },
    methods: {
        visibilityChanged (isVisible, entry, section) {
                    // console.log(isVisible, entry, section)
            if (isVisible == true) {
               if (section == 'about') {
                    // console.log(document.getElementsByClassName('menu-link-#about'))
                    document.getElementById('menu-link-#about').classList.add('active');
                    document.getElementById('menu-mobile-link-#about').classList.add('active');
                } else if (section == 'distribution') {
                    document.getElementById('menu-link-#distribution').classList.add('active');
                    document.getElementById('menu-mobile-link-#distribution').classList.add('active');
                } else if (section == 'strategy') {
                    document.getElementById('menu-link-#strategy').classList.add('active');
                    document.getElementById('menu-mobile-link-#strategy').classList.add('active');
                } else if (section == 'history') {
                    document.getElementById('menu-link-#history').classList.add('active');
                    document.getElementById('menu-mobile-link-#history').classList.add('active');
                } else if (section == 'cooperation') {
                    document.getElementById('menu-link-#cooperation').classList.add('active');
                    document.getElementById('menu-mobile-link-#cooperation').classList.add('active');
                } else if (section == 'contacts') {
                    document.getElementById('menu-link-#contacts').classList.add('active');
                    document.getElementById('menu-mobile-link-#contacts').classList.add('active');
                }
            } else {
                if (section == 'about') {
                    document.getElementById('menu-link-#about').classList.remove('active');
                    document.getElementById('menu-mobile-link-#about').classList.remove('active');
                } else if (section == 'distribution') {
                    document.getElementById('menu-link-#distribution').classList.remove('active');
                    document.getElementById('menu-mobile-link-#distribution').classList.remove('active');
                } else if (section == 'strategy') {
                    document.getElementById('menu-link-#strategy').classList.remove('active');
                    document.getElementById('menu-mobile-link-#strategy').classList.remove('active');
                } else if (section == 'history') {
                    document.getElementById('menu-link-#history').classList.remove('active');
                    document.getElementById('menu-mobile-link-#history').classList.remove('active');
                } else if (section == 'cooperation') {
                    document.getElementById('menu-link-#cooperation').classList.remove('active');
                    document.getElementById('menu-mobile-link-#cooperation').classList.remove('active');
                } else if (section == 'contacts') {
                    document.getElementById('menu-link-#contacts').classList.remove('active');
                    document.getElementById('menu-mobile-link-#contacts').classList.remove('active');
                }
            }
        }
    },
}
</script>

<style lang="scss">
    @import '@/styles/components/home.scss';
</style>


<template>
    <div class="index-promo" id="index-promo" :class="{'active' : showPromo}"
        v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'index-promo'),
            intersection: {
                threshold: .1
            },
        }"
    >
        <div class="container">
            <div class="dots-promo" :style="'background-image:url(' + dots_promo + ')'"></div>
            <div class="index-promo-gallery">
                <div class="promo-gallery">
                        

                    <swiper 
                        :modules="modules" 
                        :navigation="swiperOptions.navigation"
                        :pagination="swiperOptions.pagination"
                        :autoplay="swiperOptions.autoplay"
                        effect="fade"
                        :slides-per-view="1"
                        :space-between="0"
                        :observer="true"
                        :observe-parents="true"
                        :loop="true"
                    >
                        <swiper-slide v-for="(item, index) in mainLang.indexPromo.promo" :key="index">
                            <div class="slide-pic">
                                <img :src="item.img" alt="">
                            </div>
                            <div class="slide-content">
                                <div class="slide-logo">
                                    <img :src="logo" alt="">
                                </div>
                                <div class="slide-title">
                                    <h2>{{item.title}}</h2>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>

                <div class="swiper-controls project-info-controls">
                    <div class="swiper-pagination swiper-pagintaion-project-card" id="projectCardPagination"></div>
                    <div id="projectCardPref" class="project-prev-slide slider-btn">
                        <svgIcon name="arrow-left" />
                    </div>
                    <div id="projectCardNext" class="project-next-slide slider-btn">
                        <svgIcon name="arrow-right" />
                    </div>
                </div>
            </div>

            <div class="promo-desc">
                <div class="column-container">
                    <div class="col-left">
                        <div class="desc-images">
                            <img :src="arrows_promo" alt="" class="arrows">
                            <img :src="cubic_promo" alt="" class="cubic">
                        </div>
                        <div class="numbers-container">
                            <div class="number">
                                <number
                                    class="bold transition"
                                    ref="numberPromo"
                                    :from="numberFrom"
                                    :format="theFormat"
                                    :to="number"
                                    :duration="duration"
                                    :delay="0"
                                    :animationPaused="false"
                                    easing="Power1.easeIn"
                                    @complete="completed"/>
                            </div>
                            <div class="number-desc">
                                <p>{{mainLang.indexPromo.promo_desc.number_desc}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-right">
                        <div class="promo-full-desc">
                            <p>{{mainLang.indexPromo.promo_desc.full_desc}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { EffectFade, Autoplay, Navigation, Pagination } from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
    import svgIcon from '@/components/partials/SvgIcon.vue';
	// Import Swiper styles
    import 'swiper/scss/pagination';
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';
    export default {
        name: 'index-promo',
        components: {
        },
        components: {
            Swiper,
			SwiperSlide,
			svgIcon,
        },
        setup() {
			return {
				EffectFade,
				modules: [Autoplay, EffectFade, Navigation, Pagination],
			};
		},
        data () {
            return {
                ///
                logo: require('@/assets/images/logo-white-big.svg'),
                arrows_promo: require('@/assets/images/arrows_promo.svg'),
                cubic_promo: require('@/assets/images/cubic_promo.svg'),
                dots_promo: require('@/assets/images/dots-promo.svg'),
                ///
                showPromo: false,
                numberFrom: 1000000,
                number: 0,
                duration: 5,
                scaleClass: false,
                swiperOptions: {
                    navigation: {
                        nextEl: '#projectCardNext',
                        prevEl: '#projectCardPref'
                    },
                    pagination: {
                        el: '#projectCardPagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: false
                    },
                },
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            initNumberCounter() {
                var date = new Date();
                var time = date.getHours();
                var shipCount = 3500;
                var shipCount_oneHour = 3500 / 24; // 145 с копейками в час
                var number;
                // console.log(time, shipCount_oneHour)
                function utcdate(d){
                    return new Date(d.getFullYear(),d.getMonth(),d.getDate());
                }
                function days(a,b){
                    return Math.floor((utcdate(b)-utcdate(a))/86400000);
                }
                // define starting count and a start date: 0 is Junary - 11 is Desember
                const count=5483472, date0=new Date(2022,10,18); // 18 November 2022
                number = count + 3500*days(date0,new Date());
                number = number - shipCount;
                number = number + shipCount_oneHour*time;
                this.number = number;

                this.counterTest(number);
                setTimeout(() => {
                    this.$refs.numberPromo.play();
                }, 300);
            },
            counterTest(total) {
                var count = total; // просчитаное число с от 23.11.2022
                let shipCount_oneMinute = 3500 / 1440; // 2.4 отгрузки в минуты из расчета на 24 часа
                // 1 hour equal to 3.600.000 ms
                // 1 minute equal to 60 000 ms
                // let delay = 24 * 60000; 
                let delay = 30000; 
                const timer = setInterval(() => {
                    this.duration = 1;
                    this.numberFrom = parseInt(count);
                    count = count + shipCount_oneMinute / 2;
                    this.number = parseInt(count);
                    // console.log(this.numberFrom, this.number)
                    this.$refs.numberPromo.play();
                    // console.log(shipCount_oneMinute / 2, parseInt(count));
                }, delay)
                const clearTimer = () => {
                    clearInterval(timer)
                } // на случай если нужно грохнуть таймер
            },
            theFormat(number) {
                // return number.toFixed(2);
                return number.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            },
            completed() {
                console.log("Animation ends!");
                // this.scaleClass = true;
            },
            playAnimation() {
                console.log("Animation starts!");
                // this.scaleClass = false;
            },
            visibilityChanged (isVisible, entry, section) {
                // this.isVisible = isVisible
                // console.log(isVisible, entry, section)
                if (isVisible == true) {
                    if (section == 'index-promo') {
                        this.showPromo = true;
                    } 
                }
            }
        },
        created() {
        },
        mounted() {
            this.initNumberCounter();
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/index-promo/index-promo.scss';
    @import '@/styles/components/index-promo/index-promo-respond.scss';
</style>
<template> 
    <div class="cooperation-collapse" :class="{'active' : showCollapse}"
         v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'cop-collapse'),
            intersection: {
                threshold: .8
            },
        }"
    >
        <div class="container">

            <div class="collapse-items">
                <!-- :class="{'collapse_in' : index == 0}" -->
                <div class="item" v-for="(item, index) in mainLang.cooperationSection.cooperation_collapse" :key="index" :id="'collapse_' + index">

                    <div class="item-header">
                        <a href="#" @click.prevent="toggleCollapse('collapse_' + index, index, $event)">
                            <div class="item-plus">
                                <img :src="cop_plus" alt="">
                            </div>
                            <div class="item-title">
                                <h3>{{item.title}}</h3>
                            </div>
                            <div class="item-arrow">
                                <img :src="cop_arrow_collapse" alt="">
                            </div>
                        </a>
                    </div>

                    <div class="item-body">
                        <div class="collapse-list">
                            <ul>
                                <li v-for="(card, index) in item.check_items" :key="index">
                                    <img :src="check" alt="" class="check">
                                    <p>{{card}}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="collapse-anounce" v-if="item.check_anounce != false">
                            <p v-for="(aNitem, index) in item.check_anounce" :key="index">{{aNitem}}</p>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'cooperation-collapse',
        components: {
        },
        components: {
        },
        data () {
            return {
                showCollapse: false,
                cop_arrow_collapse: require('@/assets/images/samples/cooperation/cop_arrow_collapse.svg'),
                cop_plus: require('@/assets/images/samples/cooperation/cop_plus.svg'),
                check: require('@/assets/images/samples/cooperation/check.svg'),
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            toggleCollapse(id) {
                document.getElementById(id).classList.toggle("collapse_in");
                this.$smoothScroll({
                    scrollTo: document.getElementById(id),
                })
            },
            visibilityChanged (isVisible, entry, section) {
                if (isVisible == true) {
                    if (section == 'cop-collapse') {
                        this.showCollapse = true;
                    } 
                }
            }
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/cooperation/cooperation-collapse.scss';
    @import '@/styles/components/cooperation/respond/cooperation-collapse-respond.scss';
</style>
<template>
    <div class="about-section" id="about">
        <div class="container">
            <div class="page-title" :class="{'active' : showAboutTitle}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'about-title'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <h2><span>{{mainLang.aboutSection.pageTitle.black}}</span>{{mainLang.aboutSection.pageTitle.color}}</h2>
            </div>

            <div class="about-desc" :class="{'active' : showAboutDesc}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'about-desc'),
                    intersection: {
                        threshold: .3
                    },
                }"
            >
                <div class="desc-col left-desc">
                    <div class="in">
                        <div class="desc-logo">
                            <img :src="logo_left" alt="" class="gf_logo">
                        </div>
                        <div class="desc-info">
                            <p v-for="(item, index) in mainLang.aboutSection.leftDesc" :key="index">{{item}}</p>
                        </div>
                    </div>
                </div>
                <div class="desc-col right-desc">
                    <div class="in">
                        <div class="desc-logo">
                            <img :src="logo_right" alt="" class="gf_logo">
                        </div>
                        <div class="desc-info">
                            <p v-for="(item, index) in mainLang.aboutSection.rightDesc" :key="index">{{item}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about-cards-carousel" :class="{'active' : showCarousel}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'about-carousel'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <swiper 
                    :modules="modules" 
                    :navigation="swiperOptions.navigation"
                    :pagination="swiperOptions.pagination"
                    :breakpoints="swiperOptions.breakpoints"
                    effect="slide"
                    :speed="3000"
                    :slides-per-view="5"
                    :slides-per-group="5"
                    :space-between="50"
                    :observer="true"
                    :observe-parents="true"
                    :loop="true"
                    :autoplay="swiperOptions.autoplay"
                >
                    <swiper-slide v-for="(item, index) in mainLang.aboutSection.aboutCards" :key="index">
                        <a href="#" @click.prevent="openAboutModal(item.id)">
                            <div class="slide-pic">
                                <img :src="item.card_img" alt="">
                            </div>
                            <div class="about-btn-more">
                                {{mainLang.aboutSection.btn_more}}
                            </div>
                        </a>
                    </swiper-slide>
                </swiper>

                <div class="swiper-controls about-info-controls">
                    <div class="swiper-pagination swiper-pagintaion-about-card" id="aboutCardPagination"></div>
                    <div id="aboutCardPref" class="about-prev-slide slider-btn">
                        <svgIcon name="arrow-left" />
                    </div>
                    <div id="aboutCardNext" class="about-next-slide slider-btn">
                        <svgIcon name="arrow-right" />
                    </div>
                </div>
            </div>

            <div class="about-card-modal" v-if="modalOpen" :class="{'open' : modalOpenClass}">
                <div class="modal-header">
                    <div class="modal-logo">
                        <img :src="aboutCardArr.card_img" alt="">
                    </div>
                    <div class="modal-desc">
                        <h3>{{aboutCardArr.title}}</h3>
                        <p v-for="(item, index) in aboutCardArr.desc" :key="index">{{item}}</p>
                    </div>
                    <a href="#" @click.prevent="closeModal" class="link-close">
                        <div class="in">
                            <svgIcon name="close" />
                        </div>
                    </a>
                </div>
                <div class="modal-carousel">
                    <swiper 
                        :modules="modules" 
                        :navigation="swiperOptionsModal.navigation"
                        :pagination="swiperOptionsModal.pagination"
                        :breakpoints="swiperOptionsModal.breakpoints"
                        effect="slide"
                        :speed="1000"
                        :slides-per-view="4"
                        :slides-per-group="4"
                        :space-between="110"
                        :observer="true"
                        :observe-parents="true"
                        :loop="false"
                    >
                        <swiper-slide v-for="(item, index) in aboutCardArr.images" :key="index">
                            <div class="slide-pic">
                                <img :src="item" alt="">
                            </div>
                        </swiper-slide>
                    </swiper>

                    <div class="swiper-controls about-modal-controls" v-if="aboutCardArr.images.length > 4">
                        <div class="swiper-pagination swiper-pagintaion-about-modal" id="aboutModalPagination"></div>
                        <div id="aboutModalPref" class="about-modal-prev-slide slider-btn">
                            <svgIcon name="arrow-left" />
                        </div>
                        <div id="aboutModalNext" class="about-modal-next-slide slider-btn">
                            <svgIcon name="arrow-right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { EffectFade, Autoplay, Navigation, Pagination } from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
    import svgIcon from '@/components/partials/SvgIcon.vue';
	// Import Swiper styles
    import 'swiper/scss/pagination';
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';
    export default {
        name: 'about-section',
        components: {
            Swiper,
			SwiperSlide,
			svgIcon,
        },
        setup() {
			return {
				EffectFade,
				modules: [Autoplay, EffectFade, Navigation, Pagination],
			};
		},
        data () {
            return {
                modalOpen:false,
                modalOpenClass: false,
                aboutCardArr: null,
                showAboutTitle: false,
                showAboutDesc: false,
                showCarousel: false,
                /////
                logo_left: require('@/assets/images/gf_logo.svg'),
                logo_right: require('@/assets/images/tukano_logo.svg'),
                swiperOptions: {
                    navigation: {
                        nextEl: '#aboutCardNext',
                        prevEl: '#aboutCardPref'
                    },
                    pagination: {
                        el: '#aboutCardPagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
							spaceBetween: 20
                        },
						415: {
							slidesPerView: 2,
                            slidesPerGroup: 2,
							spaceBetween: 20
						},
						812: {
							slidesPerView: 3,
                            slidesPerGroup: 3,
							spaceBetween: 20
						},
						1024: {
							slidesPerView: 5,
                            slidesPerGroup: 5,
							spaceBetween: 50
						}
					}
                },
                swiperOptionsModal: {
                    navigation: {
                        nextEl: '#aboutModalNext',
                        prevEl: '#aboutModalPref'
                    },
                    pagination: {
                        el: '#aboutModalPagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: true
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
							spaceBetween: 10
                        },
						415: {
							slidesPerView: 1,
                            slidesPerGroup: 1,
							spaceBetween: 10
						},
						812: {
							slidesPerView: 2,
                            slidesPerGroup: 2,
							spaceBetween: 20
						},
						1024: {
							slidesPerView: 4,
                            slidesPerGroup: 4,
							spaceBetween: 80
						}
					}
                },
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            openAboutModal(id) {
                var dataArr = this.mainLang.aboutSection.aboutCards;
                var filterItem = dataArr.filter((item, index) => {
                    return item.id == id;
                });
                if (filterItem.length) {
                    this.aboutCardArr = filterItem[0];
                    setTimeout(() => {
                        this.$store.commit('Open_promo_modal', true);
                        document.getElementById('header').classList.add('open-modal');
                        document.getElementsByTagName('body')[0].classList.add('overlay-open');
                        this.modalOpen = true;
                        setTimeout(() => {
                            this.modalOpenClass = true;
                        }, 200);
                    }, 100);
                }
            },
            closeModal() {
                this.modalOpenClass = false;
                setTimeout(() => {
                    this.$store.commit('Open_promo_modal', false);
                    document.getElementsByTagName('body')[0].classList.remove('overlay-open');
                    document.getElementById('header').classList.remove('open-modal');
                    this.modalOpen = false;
                }, 300);
            },
            visibilityChanged (isVisible, entry, section) {
                // this.isVisible = isVisible
                // console.log(isVisible, entry, section)
                // console.log(isVisible, entry, section)
                if (isVisible == true) {
                    if (section == 'about-title') {
                        this.showAboutTitle = true;
                    } else if (section == 'about-desc') {
                        this.showAboutDesc = true;
                    } else if (section == 'about-carousel') {
                        this.showCarousel = true;
                    }
                } 
            }
        },
        created() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/about/about.scss';
    @import '@/styles/components/about/about-respond.scss';
</style>
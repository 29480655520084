<template>
    <div class="distribution" id="distribution">
        <div class="container">
            <div class="page-title" :class="{'active' : showTitle}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'dis-title'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <h2><span>{{mainLang.distributionSection.pageTitle.black}}</span>{{mainLang.distributionSection.pageTitle.color}}</h2>
            </div>

                <!-- throttle: 300, -->
                <!-- throttleOptions: {
                    leading: 'visible',
                }, -->
                <!-- once: true, -->



            <div class="dis-promo-container" :class="{'active' : showPromo}" 
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'dis-promo'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <div class="dots-promo" :style="'background-image:url(' + dots_promo + ')'"></div>
                <div class="dis-promo">
                    <img :src="img_promo" alt="">
                </div>
            </div>

            <div class="dis-promo-desc" :class="{'active' : showPromoDesc}" 
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'dis-promo-desc'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <div class="column-container">
                    <div class="col-right">
                        <div class="desc-images">
                            <img :src="arrows_promo" alt="" class="arrows">
                            <img :src="dis_rycle" alt="" class="cubic">
                        </div>
                        <div class="numbers-container">
                            <div class="number">
                                <number
                                    class="bold transition"
                                    ref="numberPromo"
                                    :from="numberFrom"
                                    :format="theFormat"
                                    :to="number"
                                    :duration="duration"
                                    animationPaused
                                    easing="Power1.easeIn"
                                    @complete="completed"/>
                            </div>
                            <div class="number-desc">
                                <p>{{mainLang.distributionSection.desc_number}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-left">
                        <div class="promo-full-desc">
                            <p>{{mainLang.distributionSection.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="dis-table-section" :class="{'active' : showDescTable}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'dis-table'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <div class="column-container">
                    <div class="col-left">
                        <div class="table-desc">
                            <p>{{mainLang.distributionSection.desc_table_up}}</p>
                            <img :src="dis_table" class="img-mobile ">
                            <strong>{{mainLang.distributionSection.desc_table_down}}</strong>
                        </div>
                    </div>
                    <div class="col-right">
                        <div class="table-pic">
                            <img :src="dis_table_en" alt="" v-if="globalLang == 'en'">
                            <img :src="dis_table" alt="" v-else>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dis-cards-carousel" :class="{'active' : showCarousel}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'dis-carousel'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <swiper 
                    :modules="modules" 
                    :navigation="swiperOptions.navigation"
                    :pagination="swiperOptions.pagination"
                    :autoplay="swiperOptions.autoplay"
                    :breakpoints="swiperOptions.breakpoints"
                    effect="slide"
                    :speed="3000"
                    :slides-per-view="5"
                    :slides-per-group="5"
                    :space-between="50"
                    :observer="true"
                    :observe-parents="true"
                    :loop="true"
                >
                    <swiper-slide v-for="(item, index) in dis_cards" :key="index">
                        <div class="slide-pic">
                            <img :src="item.logo" alt="">
                        </div>
                    </swiper-slide>
                </swiper>

                <div class="swiper-controls dis-info-controls">
                    <div class="swiper-pagination swiper-pagintaion-dis" id="disCardPagination"></div>
                    <div id="disCardPref" class="dis-prev-slide slider-btn">
                        <svgIcon name="arrow-left" />
                    </div>
                    <div id="disCardNext" class="dis-next-slide slider-btn">
                        <svgIcon name="arrow-right" />
                    </div>
                </div>
            </div>

            <div class="dis-cards-desc" :class="{'active' : showCarouselDesc}"
                v-observe-visibility="{
                    callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'dis-carousel-desc'),
                    intersection: {
                        threshold: .8
                    },
                }"
            >
                <div class="checkpoint">
                    <img :src="checkpoint" alt="">
                </div>
                <div class="desc-left">
                    <p>{{mainLang.distributionSection.cards_desc_left}}</p>
                </div>
                <div class="desc-right">
                    <p>{{mainLang.distributionSection.cards_desc_right}}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { EffectFade, Autoplay, Navigation, Pagination } from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
    import svgIcon from '@/components/partials/SvgIcon.vue';
	// Import Swiper styles
    import 'swiper/scss/pagination';
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';
    export default {
        name: 'distribution-section',
        components: {
        },
        components: {
            Swiper,
			SwiperSlide,
			svgIcon,
        },
        setup() {
			return {
				EffectFade,
				modules: [Autoplay, EffectFade, Navigation, Pagination],
			};
		},
        data () {
            return {
                // COMPONENT VARS
                globalLang: window.GlobalLang,
                numberFrom: 0,
                number: 0,
                duration: 7,
                scaleClass: false,
                swiperOptions: {
                    navigation: {
                        nextEl: '#disCardNext',
                        prevEl: '#disCardPref'
                    },
                    pagination: {
                        el: '#disCardPagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: true
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
							spaceBetween: 20
                        },
						415: {
							slidesPerView: 2,
                            slidesPerGroup: 2,
							spaceBetween: 20
						},
						812: {
							slidesPerView: 3,
                            slidesPerGroup: 3,
							spaceBetween: 20
						},
						1024: {
							slidesPerView: 5,
                            slidesPerGroup: 5,
							spaceBetween: 50
						}
					}
                },
                showTitle: false,
                showPromo: false,
                showPromoDesc: false,
                showDescTable: false,
                showCarousel: false,
                showCarouselDesc: false,
                /// CONTENT
                img_promo: require('@/assets/images/samples/slide-pic-02.png'),
                arrows_promo: require('@/assets/images/arrows_promo.svg'),
                dis_rycle: require('@/assets/images/dis-rycle.svg'),
                dots_promo: require('@/assets/images/dots-promo.svg'),
                dis_table: require('@/assets/images/dis_table.svg'),
                dis_table_en: require('@/assets/images/dis_table_en.svg'),
                checkpoint: require('@/assets/images/checkpoint.svg'),
                dis_cards: [
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-01.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-02.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-03.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-04.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-05.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-06.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-07.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-08.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-09.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-10.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-11.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-12.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-13.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-14.png'),
                    },
                    {
                        logo: require('@/assets/images/samples/dis-cards/dis-pic-15.png'),
                    },
                ]
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            initNumberCounter() {
                function utcdate(d){
                    return new Date(d.getFullYear(),d.getMonth(),d.getDate());
                }
                function days(a,b){
                    return Math.floor((utcdate(b)-utcdate(a))/86400000);
                }
                // define starting count and a start date: 0 is Junary - 11 is Desember
                const count=24349, date0=new Date(2022,9,31); // 31 October 2022
                this.number = count + 0.1*days(date0,new Date());
                setTimeout(() => {
                    this.$refs.numberPromo.play();
                }, 300);
            },
            theFormat(number) {
                return number.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            },
            completed() {
                // console.log("Animation ends!");
            },
            playAnimation() {
                // console.log("Animation starts!");
                // this.scaleClass = false;
            },
            visibilityChanged (isVisible, entry, section) {
                // this.isVisible = isVisible
                // console.log(isVisible, entry, section)
                if (isVisible == true) {
                    if (section == 'dis-promo') {
                        this.showPromo = true;
                    } else if (section == 'dis-promo-desc') {
                        this.showPromoDesc = true;
                        this.initNumberCounter();
                    } else if (section == 'dis-table') {
                        this.showDescTable = true;
                    } else if (section == 'dis-carousel') {
                        this.showCarousel = true;
                    } else if (section == 'dis-carousel-desc') {
                        this.showCarouselDesc = true;
                    } else if (section == 'dis-title') {
                        this.showTitle = true;
                    }
                }
            }
        },
        created() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/distribution/distribution.scss';
    @import '@/styles/components/distribution/distribution-respond.scss';
</style>
<template>
    <footer class="footer" id="footer">
        <div class="container">
            <div class="columns">
                <div class="col">
                    <div class="logo-project">
                        <a href="#">
                            <img alt="concrete block logo" :src="logo">
                        </a>
                    </div>
                    <div class="copy"><p>{{mainLang.copy}}</p></div>
                </div>
                <div class="col">
                    <a href="http://ultraproject.kz" target="_blank">
                        <p>{{mainLang.developer}}</p>
                        <img alt="victory web logo" :src="logo_up">
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    // import svgIcon from '@/components/SvgIcon.vue';
    import {mapGetters} from 'vuex'
    export default {
        name: 'AppFooter',
        data () {
            return {
                logo: require('@/assets/images/logo-white.svg'),
                logo_up: require('@/assets/images/up-logo.svg'),
               
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        components: {
            // svgIcon,
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/footer/footer.scss';
    @import '@/styles/components/footer/footer-respond.scss';
</style>
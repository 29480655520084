<template>
    <div class="cooperation-info" :class="{'active' : showInfo}"
        v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, 'cop-info'),
            intersection: {
                threshold: .7
            },
        }"
    >
        <div class="container">

            <div class="cop-info-container">
                <div class="cop-info-images">
                    <img :src="cop_arrows" alt="" class="arrows">
                    <img :src="cop_six" alt="" class="six">
                    <img :src="cop_cubic" alt="" class="cubic">
                </div>

                <div class="cop-info-desc">
                    <strong>{{mainLang.cooperationSection.desc_cooperation_top}}</strong>
                    <div class="cop-info-list">
                        <ul>
                            <li v-for="(item, index) in mainLang.cooperationSection.cooperation" :key="index">
                                <span>{{item}}</span>
                                <img :src="cop_arrow_right" alt="" v-if="index != 3">
                            </li>
                        </ul>
                    </div>
                    <strong>{{mainLang.cooperationSection.desc_cooperation_bottom}}</strong>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'cooperation-info',
        components: {
        },
        components: {
        },
        data () {
            return {
                showInfo: false,
                //
                cop_arrows: require('@/assets/images/samples/cooperation/cooperation_arrows.svg'),
                cop_cubic: require('@/assets/images/samples/cooperation/cooperation_cubic_01.svg'),
                cop_six: require('@/assets/images/samples/cooperation/cooperation_six.svg'),
                cop_arrow_right: require('@/assets/images/samples/cooperation/cop-arrow-right.svg'),
            }
        },
        computed: mapGetters({
            mainLang: 'loadMainLang',
        }),
        methods: {
            visibilityChanged (isVisible, entry, section) {
                if (isVisible == true) {
                    if (section == 'cop-info') {
                        this.showInfo = true;
                    } 
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/cooperation/cooperation-info.scss';
    @import '@/styles/components/cooperation/respond/cooperation-info-respond.scss';
</style>